import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LoginService } from '../services/login.service';
import { MainHeaderMenuService } from '../services/main-header-menu.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, public ShowHideHeaderMenu: MainHeaderMenuService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const token = this.authService.isAuthenticated();
    if (token) {
      this.ShowHideHeaderMenu.show();
      return true;
    }
    else {
      this.ShowHideHeaderMenu.hide();
      return this.router.parseUrl("/login-corp");
    }
  }

}
