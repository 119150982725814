import { isNullOrUndefined } from '../validation';
import { ConvertToJSONService } from './convert-to-json.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { EncrdecrService } from './../Auth/encrdecr.service';

const headers = { headers: new HttpHeaders({ 'content-type': 'application/json' }) }

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  myDate = new Date();
  todaysDate = this.datePipe.transform(this.myDate, 'dd/MM/yyyy');

  ServerUrl = environment.ServerUrl;
  constructor(private http: HttpClient, private datePipe: DatePipe,
    private EncrdecrService: EncrdecrService, private ConvertToJSON: ConvertToJSONService) { }

  //=======================================Common For All Transaction==================================================

  GetToken() {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }

  GetAllClient(UserType, SubBrokerId): Observable<any> {
    const tokenOption = this.GetToken();
    const url = `${this.ServerUrl}/api/ReportFilter/ReportDetails`;
    const QueryString = `TRANS_TYPE=${this.EncrdecrService.setEncr('USERCODE_WISE_CLIENT')}&LOGIN_ID=${this.EncrdecrService.setEncr('0')}&USER_TYPE=${this.EncrdecrService.setEncr(UserType)}&ZONE_ID=0&REGION_ID=0&BRANCH_ID=0&SB_ID=${this.EncrdecrService.setEncr(SubBrokerId)}&EMP_ID=0&FAMILY_ID=0&CLIENT_ID=0`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetClientUCCDetails(ClientId): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/BSETransaction/UCCDetails`;
    const QueryString = `ClientBasicInfoId=${ClientId}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    console.log("UCC API Details");
    console.log(url);
    console.log(body);
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);

    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  GetAMCName(): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/AMC/AMCList`;
    console.log("AMC API Details");
    console.log(url);

    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);

    return this.http.get<any>(url, tokenOption).pipe();
  }

  GetSchemeName(AMCCode,RiskProfileLevel=""): Observable<any> {
    var url = `${this.ServerUrl}/api/BSESchemeList/BSESchemeList?BSESchemeCode=&SchemeCode=0&AMCCode=${AMCCode}&SchemeType=&SchemeName=&PurchaseTransactionMode=&SchemePlan=&PageNumber=1&PageSize=1`;

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("Scheme API Details");
    console.log(url);

    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.get<any>(url, tokenOption).pipe();
  }

  GetSchemeNamefordirect(AMCCode,DirectCode): Observable<any> {
    const url = `${this.ServerUrl}/api/BSESchemeList/BSESchemeList?BSESchemeCode=&SchemeCode=0&AMCCode=${AMCCode}&DirectCode=${DirectCode}&SchemeType=&SchemeName=&PurchaseTransactionMode=&SchemePlan=${DirectCode}&PageNumber=1&PageSize=1`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("Scheme API Details");
    console.log(url);

    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.get<any>(url, tokenOption).pipe();
  }
  // GetSchemeName(AMCCode): Observable<any> {
  //   const url = `${this.ServerUrl}/api/BSESchemeList/BSESchemeList?BSESchemeCode=&SchemeCode=0&AMCCode=${AMCCode}&SchemeType=&SchemeName=&PurchaseTransactionMode=&SchemePlan=&PageNumber=1&PageSize=1`;
  //   let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
  //   let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
  //   let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
  //   let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
  //   const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
  //   const tokenOption = {
  //     headers:
  //       new HttpHeaders(
  //         {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Basic ${TOKEN}`,
  //         })
  //   }
  //   console.log("SchemeList url -> "+url);
  //   const body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1])
  //   return this.http.post<any>(url.split('?')[0],body, tokenOption).pipe();
  // }

  GetSelectedSchemeData(AMCCode, BSESchemeCode,SchemePlan): Observable<any> {
    //////
    const url = `${this.ServerUrl}/api/BSESchemeList/BSESchemeList?BSESchemeCode=${BSESchemeCode}&SchemeCode=0&AMCCode=${AMCCode}&SchemeType=&SchemeName=&PurchaseTransactionMode=&SchemePlan=${SchemePlan}&PageNumber=1&PageSize=1`;

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.get<any>(url, tokenOption).pipe();
  }

  BindExistingFolio(ClientId, SchemeCode, BSESchemeCode): Observable<any> {
    //////
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/TransactionGetFolioNumber/Details`;
    const QueryString = `ClientBasicInfoId=${ClientId}&SchemeCode=${SchemeCode}&BSESchemeCode=${BSESchemeCode}`;
    console.log("QueryString " + QueryString);
    console.log("URL " + url);
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }

  //=======================================FOR LUMPSUM==================================================
  InvestToLumpsum(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/InvestToLumpsum`;
    return this.http.post<any>(url, formData, this.GetToken());
  }
  //=======================================FOR SIP==================================================
  GetSIPMandate(TRANS_TYPE, UCCCode): Observable<any> {
    const url = `${this.ServerUrl}/api/ClientMandate/Details?TRANS_TYPE=${TRANS_TYPE}&UCCCode=${UCCCode}`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log('Get SIP Mandate')
    console.log(url)
    console.log(token + "@" + UserId + "|" + UserType + "|" + IpAddress)
    return this.http.get<any>(url, tokenOption).pipe();
  }

  GenerateMandate(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/ClientMandate/Details`;
    return this.http.post<any>(url, formData, this.GetToken()).pipe();
  }

  GetSIPDates(BSESchemeCode): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/BSESIPDetails/GetBSESIPDetails?BSESchemeCode=${BSESchemeCode}`;
    console.log("Get SIP Start Date");
    console.log(url);
    console.log(token + "@" + UserId + "|" + UserType + "|" + IpAddress)
    return this.http.get<any>(url, tokenOption).pipe();
  }

  InvestToSIP(formData): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/BSETransaction/InvestToSIP`;
    console.log("SIP Transaction");
    console.log(url);
    console.log(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    console.log(JSON.stringify(formData));
    return this.http.post<any>(url, formData, tokenOption);

  }
  //=======================================SIP End==================================================

  //used in switch, redeem, swp, stp
  GetFromScheme(ClientBasicInfoId): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClientWiseExistingScheme`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("Switch API From Scheme");
    console.log(url);
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.get<any>(url, tokenOption).pipe();
  } 

  GetFromScheme_New(ClientBasicInfoId): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClientWiseExistingScheme`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("Switch API From Scheme");
    console.log(url);
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    return this.http.get<any>(url, tokenOption).pipe();
  }






  //used in switch, redeem, swp, stp
  GetInvestedFolio(ClientBasicInfoId, SchemeCode): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails`;
    const QueryString = `ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClienFolioNoSchemeBased&SchemeCode=${SchemeCode}`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("Switch Folio number")
    console.log(url)
    console.log(body)
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress)
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  //used in switch, swp, stp
  IsDivOption(SchemeCode): Observable<any> {
    const url = `${this.ServerUrl}/api/DropDown/DividentDetails?Flag=AccordDividentOption&SchemeCode=${SchemeCode}`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.get<any>(url, tokenOption).pipe();
  }
  //used in swp, stp
  GetFrequency(): Observable<any> {
    const url = `${this.ServerUrl}/api/DropDown/GetDetails?Flag=BSESTPSWPFrequency`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("SWP Frequency")
    console.log(url);
    let body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);

    return this.http.post<any>(url.split('?')[0],body, tokenOption);
  }
  //used in swp, stp
  Get_STP_SWP_MinAmt(SchemeCode, TransType): Observable<any> {
    const url = `${this.ServerUrl}/api/BSESIPDetails/GetBSESIPDetails?BSESchemeCode=${SchemeCode}&Flag=STPSWPDetails&TRANS_TYPE=${TransType}`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.get<any>(url, tokenOption).pipe();
  }
  //=======================================FOR SWITCH==================================================
  InvestToSwitch(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/InvestToRedemption`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("Switch Transaction Request ")
    console.log(`${this.ServerUrl}/api/BSETransaction/InvestToSwitch`)
    console.log(JSON.stringify(formData))
    return this.http.post<any>(`${this.ServerUrl}/api/BSETransaction/InvestToSwitch`, formData, tokenOption);
  }
  //=======================================FOR Redemption==================================================
  InitiateRedemption(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/InvestToRedemption`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("Redeem Transaction")
    console.log(url);
    console.log(JSON.stringify(formData));

    return this.http.post<any>(url, formData, tokenOption);
  }
  //=======================================FOR SWP==================================================
  InvestToSWP(formData): Observable<any> {

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("SWP Transaction")
    console.log(`${this.ServerUrl}/api/BSETransaction/InvestToSWP`)
    console.log(JSON.stringify(formData))
    console.log(token + "@" + UserId + "|" + User_Type + "|" + IpAddress)
    return this.http.post<any>(`${this.ServerUrl}/api/BSETransaction/InvestToSWP`, formData, tokenOption);
  }
  //=======================================FOR STP==================================================
  GetExistingTargetScheme(ClientBasicInfoId, Schemecode, FolioNumber): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClientSchemeByFolioNo&SchemeCode=${Schemecode}&FolioNumber=${FolioNumber}`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.get<any>(url, tokenOption).pipe();
  }
  InvestToSTP(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/InvestToSTP`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("STP Transaction Service")
    console.log(url)
    console.log(JSON.stringify(formData))
    return this.http.post<any>(url, formData, tokenOption);
  }
  //======================================For SWP & SIP=====================================================
  GetSourceSchemeName(ClientBasicInfoId): Observable<any> {
    ////////
    return this.http.get<any>(`${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClientWiseExistingScheme`).pipe();
  }

  GetFolioNumber(ClientBasicInfoId, Schemecode): Observable<any> {
    return this.http.get<any>(`${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClienFolioNoSchemeBased&SchemeCode=${Schemecode}`).pipe();
  }

  GetExistingFolio(ClientBasicInfoId, Schemecode): Observable<any> {
    return this.http.get<any>(`${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?ClientBasicInfoId=${ClientBasicInfoId}&Flag=ClienFolioNoSchemeBased&SchemeCode=${Schemecode}`).pipe();
  }

  GetDividentOption(SchemeCode): Observable<any> {
    return this.http.get<any>(`${this.ServerUrl}/api/DropDown/DividentDetails?Flag=AccordDividentOption&SchemeCode=${SchemeCode}`).pipe();
  }

  GetTargetSchemeNAV(Schemecode): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/SourceSchemeDetails?Flag=BSESTPTargetScheme&SchemeCode=${Schemecode}`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    console.log("STP Target Scheme")
    console.log(url)
    return this.http.get<any>(url, tokenOption).pipe();
  }

  GetSWPMINAmt(SchemeCode, TransType): Observable<any> {
    return this.http.get<any>(`${this.ServerUrl}/api/BSESIPDetails/GetBSESIPDetails?BSESchemeCode=${SchemeCode}&Flag=STPSWPDetails&TRANS_TYPE=${TransType}`).pipe();
  }
  //======================================For SWP & SIP END====================================================


  //======================================For BSE CART START====================================================
  AddToCart(formData) {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/CartMF/Details`;
    console.log('add to cart ')
    console.log(url)
    console.log(formData)
    return this.http.post<any>(url, formData, tokenOption);
  }
  CartSummaryList(ClientBasicInfoId, SUB_BROKER_CODE) {
    var Flag ='';
    const CompanyName = environment.CompayName;
    if (CompanyName == "YesSecurities") {
      Flag = "SelectForYes";
    } else {
      Flag = "Select";
    }
    let url = `${this.ServerUrl}/api/CartMF/GetDetails`;
    //console.log(url);
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const QueryString = `Flag=${Flag}&ClientBasicInfoId=${ClientBasicInfoId}&JointClientId=0&SUBBROKERCODE=${SUB_BROKER_CODE}&EmployeeCode=`;
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url,body, tokenOption);
  }
  CartUpdateItem(formData) {
    let url = `${this.ServerUrl}/api/CartMF/Details`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(url, formData, tokenOption);
  }
  CartDeleteItems(formData) {
    let url = `${this.ServerUrl}/api/CartMF/Details`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(url, formData, tokenOption);
  }
  //======================================For BSE CART END====================================================
  NoOfInstallmetCalculator(StartDate, EndDate, Frequency) {
    let sDatesplit = StartDate.split('/');
    let SIPStartDate = sDatesplit[2] + '-' + sDatesplit[1] + '-' + sDatesplit[0];
    let eDatesplit = EndDate.split('/');
    let SIPEndDate = eDatesplit[2] + '-' + eDatesplit[1] + '-' + eDatesplit[0];
    let NumberOfInstallments = 0;
    let days = 0;
    if (SIPStartDate != "") {
      let startDate = new Date(SIPStartDate);
      let endDate = new Date(SIPEndDate);
      days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
    } else {
      let startDate = new Date(this.todaysDate);
      let endDate = new Date(SIPEndDate);
      days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
    }
    if (Frequency.toUpperCase() === "WEEKLY") { //WEEKLY / MONTHLY / QUARTERLY / SEMIANNUALLY / ANNUALLY
      NumberOfInstallments = days / 7;
    } else if (Frequency.toUpperCase() === "QUARTERLY") {
      NumberOfInstallments = days / 90;
    } else if (Frequency.toUpperCase() === "SEMIANNUALLY") {
      NumberOfInstallments = days / 180;
    } else if (Frequency.toUpperCase() === "ANNUALLY") {
      NumberOfInstallments = days / 365;
    } else if (Frequency.toUpperCase() === "DAILY") {
      NumberOfInstallments = days / 1;
    } else {
      NumberOfInstallments = days / 30;
    }
    return NumberOfInstallments.toFixed(0);
  }

  //----------------------Goal Planning: start------------------------
  RiskProfileDropdownList() {
    const token = this.GetToken();
    let url = `${this.ServerUrl}/api/InvestGoalPortfolio_Mahindra/GetRiskProfiles`;

    return this.http.get<any>(url, token);
  }
  GetClientRiskProfile(ClientBasicInfoId) {
    const token = this.GetToken();
    let url = `${this.ServerUrl}/api/ClientRiskProfile_QuestionAnswer/GetClientRiskProfileNameAndId?ClientBasicInfoId=${ClientBasicInfoId}`;
    return this.http.get<any>(url, token);
  }
  GetPortfolioList(RiskProfile, Amount, Tenure, TRANS_TYPE) {
    let url = `${this.ServerUrl}/api/InvestGoalPortfolio_Mahindra/GetDetails?Flag=&Wealthmfid=0&RiskProfile=${RiskProfile}&Amount=${Amount}&Tenure=${Tenure}&AmountRangeId=0&TRANS_TYPE=${TRANS_TYPE}`;
    const header = this.GetToken();
    return this.http.get<any>(url,header);
  }

  GetPortfolioTypeList() {
    let url = `${this.ServerUrl}/api/DropDown/GetDetails?TRANS_TYPE=ASSET_TYPE_mym&REFF_ID=&CON_STR=MF&REFF_ID2=&REFF_ID3=`;
    let body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);
    let tokenOption = this.GetToken();
    return this.http.post<any>(url.split('?')[0],body, tokenOption);

  }
  GetPortfolioAMCList() {
    let url = `${this.ServerUrl}/api/DropDown/GetDetails?TRANS_TYPE=AMCName_Goal&REFF_ID=&CON_STR=MF&REFF_ID2=&REFF_ID3=`;
    let body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);
    let tokenOption = this.GetToken();
    return this.http.post<any>(url.split('?')[0],body, tokenOption);
  }
  GetPortfolioSchemeList() {
    let url = `${this.ServerUrl}/api/DropDown/GetDetails?TRANS_TYPE=SchemeList_ClassCode&REFF_ID=400040&CON_STR=MF&REFF_ID2=49&REFF_ID3=`;
    let body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);
    let tokenOption = this.GetToken();
    return this.http.post<any>(url.split('?')[0],body, tokenOption);
  }
  //----------------------Goal Planning : end------------------------
  GetBSESChemeCode(SchemeCode, DivOption, Flag): Observable<any> {
    //////
    const s_url = `${this.ServerUrl}/api/DropDown/ReportDetails?TRANS_TYPE=BSESchemeCode&CON_STR=MF&REFF_ID=${SchemeCode}&REFF_ID2=${DivOption}`;
    //console.log(url);
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url,body, tokenOption).pipe();
  }
  SendDataTONEFT(body): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/NEFTPaymentPosting/NEFTPaymentPost`;
    return this.http.post<any>(url, body, tokenOption);
  }
  InvestToSwitchEmp(formData): Observable<any> {

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');

    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(`${this.ServerUrl}/api/BSEEMPTransaction/InvestToSwitch`, formData, tokenOption);
  }
  //=======================================FOR Redemption==================================================
  InitiateRedemptionEmp(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSEEMPTransaction/InvestToRedemption`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(url, formData, tokenOption);
  }
  //=======================================FOR SWP==================================================
  InvestToSWPEmp(formData): Observable<any> {

    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(`${this.ServerUrl}/api/BSEEMPTransaction/InvestToSWP`, formData, tokenOption);
  }

  InvestToSTPEmp(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSEEMPTransaction/InvestToSTP`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(url, formData, tokenOption);
  }
  InvestToSIPEmp(formData): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/BSEEMPTransaction/InvestToSIP`;
    return this.http.post<any>(url, formData, tokenOption);
  }
  InitiateTransaction(formData): Observable<any> {
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const url = `${this.ServerUrl}/api/BSEEMPTransaction/InitiateTransaction`;
    return this.http.post<any>(url, formData, tokenOption);
  }
  CheckTransactionData(formData): Observable<any> {

    const url = `${this.ServerUrl}api/BSEEMPCheckData/InitiateTransaction`;
    return this.http.post<any>(url, formData, headers);
  }
  sendTransactionOTP(formData) {
    //const token = this.GetToken();
    const url = `${this.ServerUrl}/api/OTPTransaction/TransactionSMS`;
    return this.http.post<any>(url, formData, headers);
  }
  SendSuccessEmailSMS(formData) {
    const token = this.GetToken();
    const url = `${this.ServerUrl}/api/CommonTransaction/TransactionSMS`;
    return this.http.post<any>(url, formData, token);
  }
  UpdateCartStatus(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/UpdateCartStatus`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(url, formData, tokenOption);
  }
  InsertBucketIntoCart(formData): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/InsertBucketDataIntoCart`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let User_Type = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + User_Type + "|" + IpAddress);
    const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    return this.http.post<any>(url, formData, tokenOption);
  }
  GetPortfolioData(TransType, RefId, ConStr, RefId2, RefId3) {
    let url = `${this.ServerUrl}/api/DropDown/GetDetails?TRANS_TYPE=${TransType}&REFF_ID=${RefId}&CON_STR=${ConStr}&REFF_ID2=${RefId2}&REFF_ID3=${RefId3}`;
    let body = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1]);
    let tokenOption = this.GetToken();
    return this.http.post<any>(url.split('?')[0],body, tokenOption);
  }
  RiskProfileDropdownList_New() {
    const token = this.GetToken();
    let url = `${this.ServerUrl}/api/InvestGoalPortfolio/GetRiskProfiles`;
    return this.http.post<any>(url, null,token);
  }
  GetPortfolioList_New(formdata:any) {
    let url = `${this.ServerUrl}/api/InvestGoalPortfolio/GetDetails`;
    const header = this.GetToken();
    return this.http.post<any>(url, formdata, header);
  }
  SavePortFolioData(formdata:any) {
    let url = `${this.ServerUrl}/api/InvestGoalPortfolio/SaveInvestGoalPortfolio`;
    const header = this.GetToken();
    return this.http.post<any>(url, formdata, header);
  }
  GeneratePaymentGatewayLink(UCCCode,ClientBasicInfoId,TransType) {
    let url = `${this.ServerUrl}/api/BSETransaction/BSEPaymentURL?UCCCode=${UCCCode}&ClientBasicInfoId=${ClientBasicInfoId}&TRANS_TYPE=${TransType}`;
    const header = this.GetToken();
    return this.http.get<any>(url, header);
  }
  GetBSESchemeCode(SchemeCode,FolioNo) {
    let url = `${this.ServerUrl}/api/BSETransaction/GetBSESchemeCode?SchemeCode=${SchemeCode}&FolioNumber=${FolioNo}&Flag=BSESchemeCode`;
    const header = this.GetToken();
    const json = this.ConvertToJSON.QueryStringToJSON(url.split('?')[1])
    return this.http.post<any>(url.split('?')[0],json, header);
  }
  CheckAvailBalance(CLientId){
    const url = `${this.ServerUrl}/api/LiabilityAccountService/GetCustomerAccountBalanceDetails`;
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    const TOKEN = btoa(token + "@" + UserId + "|" + UserType + "|" + IpAddress);
       const tokenOption = {
      headers:
        new HttpHeaders(
          {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${TOKEN}`,
          })
    }
    const checkAmount ={

        "TOKEN_ID":token,
        "ClientBasicInfoId":CLientId
    }
    const json = JSON.stringify(checkAmount);

    return this.http.post<any>(url,json,tokenOption);
  }
  GetMandateActivationURL(body:any): Observable<any> {
    const url = `${this.ServerUrl}/api/ClientMandate/GetMandateActivationURL`;
   const tokenOption = this.GetToken();
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  CheckGatewayUrl(Databody): Observable<any> {
    const tokenOption  = this.GetToken();
    const url = `${this.ServerUrl}/api/DirectpaymentGateway/DirectPaymentGateway`;
    return this.http.post<any>(url, Databody, tokenOption);
  }
  sendNPSOTP(formData) {
    //const token = this.GetToken();
    const url = `${this.ServerUrl}/api/OTPTransaction/TransactionSMS`;
    return this.http.post<any>(url, formData, headers);
  }
GetThankYouData(body){
  const url = `${this.ServerUrl}/api/Authenticate/CheckClientData`;
    return this.http.post<any>(url, body, headers).pipe();
}
GetGoalInvestmentSchemeList(formData,method): Observable<any> {
  const url = `${this.ServerUrl}/api/ExploreFund/${method}`;
  
  return this.http.post<any>(url, formData, this.GetToken());
}
  //Added By Jay
  GetSTPSWPDetails(body): Observable<any> {
    const url = `${this.ServerUrl}/api/BSETransaction/GetBSESWPSTPSchemeDetails`;
    return this.http.post<any>(url, body, this.GetToken());
  }
}
