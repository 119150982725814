import { ConvertToJSONService } from './convert-to-json.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EncrdecrService } from './../Auth/encrdecr.service';
const headers = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  SetClientBasicInfoId : number;
  SetClientBasicInfoIdPurchase : number;
  SetClientBasicInfoIdSip : number;
  isGenerateUcc : boolean;
  SelectedFamily : string;

  ServerUrl = environment.ServerUrl;

  constructor(private http:HttpClient,private EncrdecrService: EncrdecrService,
    private ConvertToJSON: ConvertToJSONService) { }

  GetAllClientsList(SubbrokerId,UserType =0 ,RoleId= 0): Observable<any> {

    // let url = `${this.ServerUrl}/api/ClientList/Details?SB_ID=${SubBrokerId}`;
   let url = '';
   if(UserType == 2){
   url = `${this.ServerUrl}/api/ClientList/Details?&SB_ID=${this.EncrdecrService.setEncr(SubbrokerId)}`;
   }
   else if(UserType ==1 && RoleId ==6){
     url = `${this.ServerUrl}/api/ClientList/Details?&EmployeeCode=${this.EncrdecrService.setEncr(SubbrokerId)}`;
   }
   else if(UserType == 1 && RoleId >= 1 && RoleId <=5){
     url = `${this.ServerUrl}/api/ClientList/Details?&ClientBasicInfoId=${this.EncrdecrService.setEncr(SubbrokerId)}`;
   }
   console.log("client list URL "+url);
   const header = this.GetToken();
    return this.http.get<any>(url,header).pipe();
  }

  GetAllFamilyList(SubBrokerId,UserType): Observable<any> {
  const url = `${this.ServerUrl}/api/ClientList/FamilyWiseList?SB_ID=${SubBrokerId}&USER_TYPE=${UserType}`;
    const header = this.GetToken();
  console.log('Family Get LIST URL '+ url);

    return this.http.get<any>(url,header).pipe();
  }

  AddFamily(formData:any): Observable<any> {
    const header = this.GetToken();
    let url = `${this.ServerUrl}/api/ClientFamilyGroup/Details`
    return this.http.post<any>(url,formData, header).pipe();
  }
  DeleteFamily(formData:any): Observable<any> {
    let url = `${this.ServerUrl}/api/UpdateClientFamilyGroup/EmpSubManageFamily`
    return this.http.post<any>(url,formData, headers).pipe();
  }
  AddDeleteFamilyMember(formData:any): Observable<any> {
    let url = `${this.ServerUrl}/api/UpdateClientFamilyGroup/EmpSubManageFamily`
    return this.http.post<any>(url,formData, headers).pipe();
  }
  SendLoginDetails(ClientId,SubBrokerId="",SubBrokerName=""): Observable<any> {
    return this.http.get<any>(`${this.ServerUrl}/api/SendLoginDetails/LoginCredential?ClientBasicInfoId=${ClientId}&SB_ID=${SubBrokerId}&SubBrokerName=${SubBrokerName}`).pipe();
  }

  //#region added for Client merge by mohit on 12-09-2020
  GetFromClientToMerge(ClientId): Observable<any> {
    return this.http.get<any>(`${this.ServerUrl}/api/SendLoginDetails/LoginCredential?ClientBasicInfoId`).pipe();
  }
  GetToClientToMerge(ClientId): Observable<any> {
    return this.http.get<any>(`${this.ServerUrl}/api/SendLoginDetails/LoginCredential?ClientBasicInfoId`).pipe();
  }

  MergeClient(fromClientId, toClientId): Observable<any> {
    // FromClientIds = '1,2,3,4';
    // ToClientId = '5'       ;
    const tokenOption = this.GetToken();
    const url = `${this.ServerUrl}/api/SignUp/ClientMapping`;
    const body = { "fromClientId": fromClientId, "toClientId": toClientId}
    return this.http.post<any>(url,body,tokenOption);
    //return this.http.get<any>(`${this.ServerUrl}/api/SignUp/ClientMapping?fromClientId=${FromClientIds}&toClientId=${ToClientId}`).pipe();
    //return this.http.get<any>(`${this.ServerUrl}/api/SendLoginDetails/LoginCredential?ClientBasicInfoId=`).pipe();
  }
  //#endregion

  // added for NSEIIN Report
  GetNSEClients(SubBrokerId,UserType = 2): Observable<any> {
    ////////
    const header = this.GetToken();
    if(UserType==3){
      return this.http.get<any>(`${this.ServerUrl}/api/ClientList/Details?ClientBasicInfoId=${this.EncrdecrService.setEncr(SubBrokerId)}`,header).pipe();
    }
    else{
    return this.http.get<any>(`${this.ServerUrl}/api/ClientList/Details?SB_ID=${this.EncrdecrService.setEncr(SubBrokerId)}`,header).pipe();
    }
  }
  // End of NSE IIN Report

  GetDataFromService(PANNO,MobileNo,Usertype,UserId,Flag:string = ''):any{

    const url = `${this.ServerUrl}/api/Customer360Service/SearchCustomerDetails`;
    const request = { "PANNo": PANNO, "MobileNo": MobileNo,"USER_ID":UserId,"USER_TYPE":Usertype,"TRANS_TYPE":Flag };
    const body = JSON.stringify(request);
    return this.http.post<any>(url,body,headers);
  }
  SaveAUClientBankDetails(body):any{
    const url = `${this.ServerUrl}/api/NetBanking/SaveClientDefaultBank`;
    return this.http.post<any>(url,body,headers);
  }
  GetToken(){
    let token = sessionStorage.getItem('`huR,8@RK9^??b4 -.hfs!!uR,XX');
    let UserId = this.EncrdecrService.getDecr(sessionStorage.getItem('m5JkoXISmYRAIuY'));
    let UserType = this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'));
    let IpAddress = sessionStorage.getItem('Tgsh@#3734fjfskshh==');
    console.log('token value is '+ token +"@"+UserId+"|"+UserType+"|"+IpAddress);
    const TOKEN = btoa(token +"@"+UserId+"|"+UserType+"|"+IpAddress);
     const tokenOption = { headers:
      new HttpHeaders(
        {'Content-Type': 'application/json',
        'Authorization': `Basic ${TOKEN}`,
      })
    }
    return tokenOption;
  }
  GetAllClientsListForMerge(ClientName: any): Observable<any> {

    let s_url = `${this.ServerUrl}/api/SignUp/Getclientdetailsformerge?&ClientName=${ClientName}`
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSON(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
  CreateNewClient_YesSecurirt(ClientId:any): Observable<any> {

    let s_url = `${this.ServerUrl}/api/AutoLogin/CreateNewClient?REFF_ID=${ClientId}`
    //return this.http.get<any>(url);
    const tokenOption = this.GetToken();
    const url = s_url.split('?')[0];
    const QueryString = s_url.split('?')[1];
    const body = this.ConvertToJSON.QueryStringToJSONWithEncryptedValue(QueryString);
    return this.http.post<any>(url, body, tokenOption).pipe();
  }
}
