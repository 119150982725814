export const environment = {
  production: true,
  CommonBaseHref:'',
  CompayName: 'HDFC',
  CompanyId:1,
  environmentDigio:"sandbox",
  // ServerUrl:  ' http://10.34.136.66:8083/',
  // ServerUrl:  'http://optimumawrapperuat.jmfonline.in/',

  //hdfc URL
  //ServerUrl: 'http://localhost:8086/MF/', //uat local wrapper url  

  // ServerUrl:  'https://uatpartnersapi.hdfcsec.com/MF/', //uat domain wrapper url  
  // HDFCFranchiseURL : "https://uatpartners.hdfcsec.com/"

  // ForLive
  ServerUrl:  'https://partnersapi.hdfcsec.com/MF/', //Live wrapper url  
  HDFCFranchiseURL : "https://partners.hdfcsec.com/"
};



