import { FormBuilder, FormGroup,Validators,FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { NseOnboardingService } from './../../services/nse-onboarding.service';
import { ProfileService } from '../../services/profile.service';
import { EncrdecrService } from '../../Auth/encrdecr.service';
import { IsValidPAN, IsValidMobile, IsValidPinCode, IsMICRCode, NumberOnly, CharacterANDSpaceOnly, IFSCCode, IsValidDate, CharacterAndOptionalSpace, IsValidName, IsValidAddress } from '../../validation';
import { TransactionService } from '../../services/transaction.service';
import { TokenExpireService } from '../../services/token-expire.service';
import { CustomLoaderService } from '../../services/custom-loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { VariableService } from 'src/app/services/variable.service';

declare var $: any;

@Component({
  selector: 'app-add-nominee',
  templateUrl: './add-nominee.component.html',
  styleUrls: ['./add-nominee.component.scss']
})
export class AddNomineeComponent implements OnInit {

  clientDetailsForm: FormGroup;
  clientDetailsFormSubmitted: boolean = false;
  isGuardianNameshow : boolean = false
isGuardianRelationshow : boolean = false
  constructor(
    private fb: FormBuilder,private NseOnboardingService: NseOnboardingService,
    private BsModalService: BsModalService,
    private clientProfileService: ProfileService,
    private EncrdecrService: EncrdecrService,
    private TransactionService: TransactionService,
    private Loader: CustomLoaderService,
    private Logout: TokenExpireService,
    private bsModalRef: BsModalRef,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private appVariable:VariableService,
  ) {
    this.clientDetailsForm = this.fb.group({
      NomineeName :['',[Validators.required]],
      NomineeDateofBirth : ['',Validators.required],
      NomineeRelation : ['',Validators.required],
      Isnominateminor : [''],
      GuardianName :['',],
      GuardianRelation :[''],
      NomineePAN:['']
    })
  }

 //variable declaration

  G_SubBrokerCode: string;
  G_ClientBasicInfoId: any;
  IsMinor: boolean = false;
  OFS_Client: number = 0;
  IsMobile: boolean = false;
  ClientPanNo: string = '';
  RelationList: any = [];
  QuestionList: any[];
  QustionArr: any = [];
  GeneratedUCCCode: any;
  G_UCCCode: string = "0";
  IsIFA: boolean = false;
  FullName : any;
  MobileNo : any;
  Email : any;
  DOB : any;
  Fatcasuccess : boolean;
  Nomuneesuccess : boolean;
  IsUCCGenerated: boolean = true;
  IsAOFUploaded: boolean = true;


  ngOnInit() {
    this.Loader.show();
    var tempcbid = this.EncrdecrService.getDecr(sessionStorage.getItem('N1QJHdOkO7Hz8QC'));
    this.G_ClientBasicInfoId = tempcbid;
    this.IsIFA = sessionStorage.getItem('Vbhs@#%LMKIo=') == 'C' ? false : true;

    if (sessionStorage.getItem('Hldq31TLYwRbLJ8') != null && sessionStorage.getItem('Hldq31TLYwRbLJ8') != undefined) {
      this.G_SubBrokerCode = this.EncrdecrService.getDecr(sessionStorage.getItem('Hldq31TLYwRbLJ8'));
    }
    this.OFS_Client = parseInt(
      this.EncrdecrService.getDecr(
        sessionStorage.getItem("Abodhj#@jdkd/%^82ncdbcnggshd!@#$")
      )
    );


    this.BindRelation();
    this.BindNomineeData();
  }

  get j() {
    return this.clientDetailsForm.controls;
  }
  get fd() {
    return this.clientDetailsForm.controls;
  }

  OnBlurDOB(e) {
    //////
    if (e.target.value != '') {
      this.clientDetailsForm.controls['DOB'].setValue(e.target.value)
    }
    else {
      this.clientDetailsForm.controls['DOB'].setValue(this.clientDetailsForm.controls['DOB'].value);
    }
  }

  BindRelation() {
    this.clientProfileService.GetRelationList().subscribe(
      (res: any) => {

        if (res != null) {
          if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
            this.Loader.hide();
            return;
          }
          else {
            let a = res.ArrayOfResponse;
            let b = a.slice(1);
            this.RelationList = b;
            this.Loader.hide();
          }
        }
        else{
          this.Loader.hide();
        }
      },
      err=>{
        this.Loader.hide();
      });
  }



ngAfterViewInit() {

  $(".clsCommonDatePicker").datepicker({
    autoclose: true,
    endDate: '0',
    format: "dd/mm/yyyy",
    todayBtn: 'linked',
    todayHighlight: true,
    orientation: "auto bottom",
  });
  var date = new Date();
  $(".clsPOAExpireDate").datepicker({
    autoclose: true,
    startDate: date,
    format: "dd/mm/yyyy",
    todayBtn: 'linked',
    todayHighlight: true,
    orientation: "auto bottom",
  });

  $(".clsDatePickerDOB").on('change', (e: any) => this.clientDetailsForm.controls['DOB'].setValue(e.target.value));
  $(".clsDatePickerNomineeDOB").on('change', (e: any) => this.clientDetailsForm.controls['NomineeDateofBirth'].setValue(e.target.value));

  $('select').select2();


  $(".CustomSelectNomRel").attr("data-placeholder","Relationship With Nominee");
  $(".CustomSelectNomRel").select2();
  $(".CustomSelectGuarRel").attr("data-placeholder","Relationship With Guardian");
  $(".CustomSelectGuarRel").select2();
  $('.CustomSelectNomRel').on('change', (e: any) => this.clientDetailsForm.controls['NomineeRelation'].setValue(e.target.value));
  $('.CustomSelectGuarRel').on('change', (e: any) => this.clientDetailsForm.controls['GuardianRelation'].setValue(e.target.value));
};

  modalClose() {
  this.bsModalRef.hide();
  }

  CallAlertModal(msg:any){
    $("#AlertModalprofilemodels").modal({
      backdrop:'static',
      keyboard:false
    });
    $('#AlertModalprofilemodelsContentmodels').text(msg);
  }

   ageCalculator(dob: any) {

    var txtDOB = dob.split("/");
    var dateString = txtDOB[2] + '/' + txtDOB[1] + '/' + txtDOB[0];
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  IsNomineeMinor(event: any) {
    if (event.target.value != '') {
      this.clientDetailsForm.controls['NomineeDateofBirth'].setValue(event.target.value)
    }
    else {
      this.clientDetailsForm.controls['NomineeDateofBirth'].setValue(this.clientDetailsForm.controls['NomineeDateofBirth'].value);
    }
    let age = this.ageCalculator(event.target.value);
    if (age < 18) {
      this.IsMinor = true;
      this.clientDetailsForm.get('GuardianName').setValidators([Validators.required, Validators.pattern(CharacterANDSpaceOnly)]);
      this.clientDetailsForm.get('GuardianName').updateValueAndValidity();
    }
    else {
      this.IsMinor = false;
      this.clientDetailsForm.get('GuardianName').clearValidators();
      this.clientDetailsForm.get('GuardianName').updateValueAndValidity();
    }
  }
  RelodePage() {
    location.reload();
  }
  onSubmitNomineeDetails() {
console.log(this.appVariable.PanNo);
    this.clientDetailsFormSubmitted = true;
    if (this.clientDetailsForm.invalid) {
      this.Loader.hide();

      return;
    }else{
      this.Loader.show();
      let TempsDataBody = {
        "ClientBasicInfoId": this.G_ClientBasicInfoId,
        "FirstHolderPANNO": this.appVariable.PanNo,
        //this.EncrdecrService.setEncr(this.BasicDetailsForm.controls['FirstHolderPANNO'].value),
        "SecondHolderPANNO": "",
        "HoldingType": "SI",
        "SubBrokerCode": this.G_SubBrokerCode,
        "EmployeeCode": "",
        "Flag": "NomineeDetails",
        "CreatedBy": "1",
        "CreatedSourceName": "onboarding",
        "NomineeDetails": {
          "First": {
            "NomineeName": this.clientDetailsForm.controls['NomineeName'].value,
            "NomineeIsMinor": this.clientDetailsForm.controls['Isnominateminor'].value,
            "Allocation": "100",
            "RelationshipId": parseInt(this.clientDetailsForm.controls['NomineeRelation'].value),
            "GardianName": this.clientDetailsForm.controls['GuardianName'].value,
            "NomineeDOB": this.clientDetailsForm.controls['NomineeDateofBirth'].value,
            "NomineePan": this.EncrdecrService.setEncr(this.clientDetailsForm.controls['NomineePAN'].value),
            "GuardianRelation": parseInt(this.clientDetailsForm.controls['GuardianRelation'].value),
            // "NomineeAddress": this.clientDetailsForm.controls['NomineeAddress'].value
          }
        }
      };
      let DatasBody = JSON.stringify(TempsDataBody);
      this.clientProfileService.PostBasicDetails(DatasBody).subscribe(
        (result: any) => {
          if (result != null) {
            if (result.Message != null && result.Message != '' && result.Message.indexOf('Not Authorized') > -1) {
              this.Loader.hide();
              this.Logout.LogoutUser();
              return;
            }else{
              if (result.Message.includes('success')) {
                //#region  Once Nominee update in out database then we call bse api to update nominee details @ BSE
                this.UpdateNomineeOnBSE();
                //#endregion
              } else {
                this.Loader.hide();
                this.CallAlertModal(result.Message);
              }
            }

          }
          else{
            this.Loader.hide();
          }
        },
        err => {
          this.Loader.hide();
        });
    }
  }


  IsNomineeMinorClick(e) {

    var checked = e.target.checked;
    if (checked) {
      this.isGuardianNameshow = true;
      this.isGuardianRelationshow = true;
      this.clientDetailsForm.controls['GuardianName'].setValidators([Validators.required]);
      this.clientDetailsForm.controls['GuardianName'].updateValueAndValidity();
      this.clientDetailsForm.controls['GuardianRelation'].setValidators(Validators.required);
      this.clientDetailsForm.controls['GuardianRelation'].updateValueAndValidity();
    } else {
      this.isGuardianNameshow = false;
      this.isGuardianRelationshow = false;
      this.clientDetailsForm.controls['GuardianName'].clearValidators();
      this.clientDetailsForm.controls['GuardianName'].updateValueAndValidity();
      this.clientDetailsForm.controls['GuardianRelation'].clearValidators();
      this.clientDetailsForm.controls['GuardianRelation'].updateValueAndValidity();
    }
  }
  OnContinueClick(){
    sessionStorage.setItem(
      "N1QJHdOkO7Hz8QC",
      this.EncrdecrService.setEncr(this.G_ClientBasicInfoId)
    );
    this.bsModalRef.content.action.next(true);
    this.bsModalRef.hide();
  }
  OpenTermsModal(TermsAndCondition) {
    sessionStorage.setItem('JIhgy76J7=8j+','pop');
    this.modalService.open(TermsAndCondition);
  }
ValidatePattern(e) {
    const k = (e.which) ? e.which : e.keyCode;
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32) {

    }
    else {
      e.preventDefault();
    }

}
CloseAndRedirect(){
  this.modalClose();
  this.router.navigate(["/client-dashboard"], {
    relativeTo: this.route,
  });
}
HideOnePager(){
  this.bsModalRef.hide();
  }
SkipNomineeDetails(){
  this.CallNomineeSkipModal('You have chosen to opt-out from nomination. As per SEBI guidelines, you will need to submit a physical declaration for each folio with respective AMC for opting out of nomination. In case this declaration is not provided, redemptions will not be allowed from the scheme. Please reach out to your nearest branch for the declaration form.');
  //this.SetActiveTab('BankDetails');
}
CallNomineeSkipModal(msg: any) {
  $("#OnePagerNomineeDeclarationModel").modal({
    backdrop: 'static',
    keyboard: false
  });
  $('#OnePagerNomineeDeclarationModelContentmodels').text(msg);
}
NomineeDeclaration(){
  const request ={
    ClientBasicInfo: this.G_ClientBasicInfoId,
    NomineeDeclarationFlag:'Yes'
  }
  this.clientProfileService.UpdateNomineeDeclaration(JSON.stringify(request)).subscribe((res)=>{
    if(res.ID==0) {
      this.CallAlertModal(res.Message)
    }
  },(error) => {
    this.Loader.hide();
  });
}

UpdateNomineeOnBSE(){
  this.clientProfileService.GetGenerateUCC(this.G_ClientBasicInfoId).subscribe(
    (res: any) => {

      if (res != null) {
        if (res.Message != null && res.Message != '' && res.Message.indexOf('Not Authorized') > -1) {
          this.Loader.hide();
          this.Logout.LogoutUser();
          return;
        }
        else {
          if (res.ID == 1 || res.ID=="1") {
            this.Loader.hide();
            this.bsModalRef.content.onClose.next(true);
            this.bsModalRef.hide();
            }
          else {
            this.Loader.hide();
            this.CallAlertModal(res.Message);
          }
        }
      }
    },
    err => {
      this.Loader.hide();
    }
  );
}

BindNomineeData(){
  
  this.clientProfileService
                .GetClientInfo(this.G_ClientBasicInfoId)
                .subscribe(
                  (res: any) => {
                    //console.log(JSON.stringify(res));
                    if (res != null) {
                      if (
                        res.Message != null &&
                        res.Message != "" &&
                        res.Message.indexOf("Not Authorized") > -1
                      ) {
                        this.Loader.hide();
                        this.Logout.LogoutUser();
                        return;
                      } else {
                        // Nominee
                        if (
                          res.ArrayOfResponse[0].nomineeDetails[0].active[0]
                            .nominee.length > 0
                        ) {
                          let nomineeDetails =
                            res.ArrayOfResponse[0].nomineeDetails[0].active[0]
                              .nominee[0];
                          this.clientDetailsForm.controls[
                            "NomineeName"
                          ].setValue(nomineeDetails.invnomname);
                          this.clientDetailsForm.controls[
                            "NomineeDateofBirth"
                          ].setValue(nomineeDetails.dob);
                          this.clientDetailsForm.controls[
                            "NomineePAN"
                          ].setValue(
                            this.EncrdecrService.getDecr(
                              nomineeDetails.NomineePan
                            )
                          );
                          //this.NomineeDetailsForm.controls['NomineeRelation'].setValue(nomineeDetails.RelationshipId);
                          setTimeout(() => {
                            $(".CustomSelectNomRel")
                            .val(nomineeDetails.RelationshipId)
                            .trigger("change");
                          }, 1000);

                          // this.clientDetailsForm.controls[
                          //   "NomineeAddress"
                          // ].setValue(nomineeDetails.NomineeAddress);
                          this.IsMinor = nomineeDetails.isminor;
                          this.clientDetailsForm.controls[
                            "Isnominateminor"
                          ].setValue(nomineeDetails.isminor);
                          if (this.IsMinor) {
                            this.isGuardianNameshow = true;
                            this.isGuardianRelationshow = true;
                            this.clientDetailsForm.controls['GuardianName'].setValidators([Validators.required]);
                            this.clientDetailsForm.controls['GuardianName'].updateValueAndValidity();
                            this.clientDetailsForm.controls['GuardianRelation'].setValidators(Validators.required);
                            this.clientDetailsForm.controls['GuardianRelation'].updateValueAndValidity();
                          } else {
                            this.isGuardianNameshow = false;
                            this.isGuardianRelationshow = false;
                            this.clientDetailsForm.controls['GuardianName'].clearValidators();
                            this.clientDetailsForm.controls['GuardianName'].updateValueAndValidity();
                            this.clientDetailsForm.controls['GuardianRelation'].clearValidators();
                            this.clientDetailsForm.controls['GuardianRelation'].updateValueAndValidity();
                          }

                          this.clientDetailsForm.controls[
                            "GuardianName"
                          ].setValue(nomineeDetails.GaurdianName);
                          setTimeout(() => {
                            
                            $(".CustomSelectGuarRel")
                            .val(nomineeDetails.GuardianRelation)
                            .trigger("change");
                          }, 1000);

                        }

                      }
                    }
                    this.Loader.hide();
                  },
                  (err) => {
                    this.Loader.hide();
                    //console.log(err);
                  }
                );
}
}
