<div *ngIf="isLoggedIn$ | async as isShowHeader">
  <nav class="navbar fixed-top">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex align-items-center">
        <div *ngIf="!IsMobile" class="hamrburg CursorPointer" (click)="OnClickHamerburg()">
          <i class="fa fa-bars" aria-hidden="true" style="font-size: 17px;"></i>
        </div>
        <a class="navbar-brand p-0">
          <img src="../../../assets/images/HDFC_Logo.png" alt="Logo" class="logo">
        </a>
      </div>
      <div class="RightSideMenu">
        <img src="../../../assets/images/Notification.svg" class="HeaderIcons" />
        <img src="../../../assets/images/Cart.svg" class="HeaderIcons" routerLink="/transaction-new/cart"
          routerLinkActive='active' />
        <!-- <a *ngIf="!IsMobile && UserType != '1'" class="navbar-brand p-0 mr-0 ml-2">
          <img src="../../../assets/images/JM/IFD_Logo.png" alt="Logo" class="Companylogo_Web">
        </a> -->
      </div>

    </div>
  </nav>
  <!-- <div class="BorderRed"></div> -->
  <div class="MarginTopAsHeader">
    <nav [ngClass]='IsMobile ? "d-flex":"d-none"' class="navbar fixed-bottom bg-white">
      <ul class="navbar-nav m-0 w-100 text-center">
        <li class="nav-item active" (click)="onClickFooterMenu('Home')" routerLink="/dashboard-ifd"
          routerLinkActive='active'>
          <img *ngIf="!IsHomeActive" src="../../../assets/images/JM/Home_Inactive.svg" class="" />
          <img *ngIf="IsHomeActive" src="../../../assets/images/JM/Home_Active.svg" class="" />
          <a [ngClass]="IsHomeActive ? 'ClrRed_n':''" class="nav-link">Home</a>
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Act')" routerLink="/transaction-new/lumpsum"
          routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsActNowActive" src="../../../assets/images/JM/ActNow_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsActNowActive" src="../../../assets/images/JM/ActNow_Active.svg" class="" />
          <a [ngClass]="IsActNowActive ? 'ClrRed_n':''" class="nav-link">Act Now</a>
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Add')" routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsAddActive" src="../../../assets/images/JM/Menu-Plus.svg" class=" AddImg" />
          <img alt="N/A" *ngIf="IsAddActive" src="../../../assets/images/JM/Menu-Close.svg" class=" AddImg" />
          <!-- <a class="nav-link"></a> -->
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Loyalty')" routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsLoyaltyActive" src="../../../assets/images/JM/Loyalty_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsLoyaltyActive" src="../../../assets/images/JM/Loyalty_Active.svg" class="" />
          <a [ngClass]="IsLoyaltyActive ? 'ClrRed_n':''" class="nav-link">Loyalty</a>
        </li>
        <li class="nav-item" (click)="onClickFooterMenu('Menu')" routerLinkActive='active'>
          <img alt="N/A" *ngIf="!IsMenuActive" src="../../../assets/images/JM/Menu_Inactive.svg" class="" />
          <img alt="N/A" *ngIf="IsMenuActive" src="../../../assets/images/JM/Menu_Active.svg" class="" />
          <a [ngClass]="IsMenuActive ? 'ClrRed_n':''" class="nav-link">Menu</a>
        </li>
      </ul>
    </nav>

    <div *ngIf="!IsMobile && UserType != '3'">
      <div id="header" [ngClass]="IsShow">
        <ul [class.collapsed]="IsCollapseSideMenu" class="Sidemenu">
          <li class="UserDetails">
            <a class="Custom_a" [ngClass]="IsCollapseSideMenu ? 'Li_a_Padding' : ''" (click)="GoToProfile()">
              <div *ngIf="IsCollapseSideMenu" class="CollapseUser"><img src="../../../assets/images/JM/Profile_Icon.png"
                  class="UserImgDefault" /></div>
              <div *ngIf="!IsCollapseSideMenu" class="d-flex justify-content-start align-items-center BorderBott">
                <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
                <span class="UserName">{{UserName}}</span>
              </div>
            </a>
          </li>

          <li class="" (click)="GoToHome()">
            <a class="MenuGrid">
              <img class="svg" src="../../../assets/images/JM/Home_new.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Home</span>
            </a>
          </li>
          <li *ngFor="let item of MenuList; let i = index" class="nav-item" routerLinkActive='active'>
            <div *ngIf="item.MainMenuURL != ''">
              <a class="d-flex align-items-center" [routerLink]="item.MainMenuURL"><img
                  src="{{GetImgSource(item.MainMenuName)}}" class="svg" /><span *ngIf="!IsCollapseSideMenu"
                  class="d-flex align-items-center">{{item.MainMenuName}}</span></a>
            </div>
            <div *ngIf="item.MainMenuURL == ''">
              <a class="nav-link nav-link_Arrow collapsed text-truncate" href='#{{ReplaceChar(item.MainMenuName)}}'
                data-toggle="collapse" data-target="#{{ReplaceChar(item.MainMenuName)}}">
                <img src="{{GetImgSource(item.MainMenuName)}}" /><span class="MainMenu"
                  *ngIf="!IsCollapseSideMenu">{{item.MainMenuName}}</span>
              </a>
              <div class="collapse Trans" id="{{ReplaceChar(item.MainMenuName)}}" aria-expanded="false"
                *ngIf="!IsCollapseSideMenu">
                <ul class="flex-column pl-5 nav">
                  <li class="nav-item" *ngFor="let submenu of item.data">
                    <a class="nav-link DropMenu MainSubMenu"
                      [routerLink]="submenu.SubMenuURL">{{submenu.SubMenuName}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#sgb" data-toggle="collapse"
              data-target="#sgb">
              <img src="../../../assets/images/Sidebar/SGB.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">SGB</span>
            </a>
            <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="sgb" aria-expanded="false">
              <ul class="flex-column pl-5 nav">
                <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/SGB/SGBInvest"
                    routerLinkActive="active"><span>Apply for SGB</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/SGB/SGBOrderBook"
                    routerLinkActive="active"><span>Order Book</span></a></li>
              </ul>
            </div>
          </li> -->
          <li class=" " (click)="LogOut()"><a class="d-flex align-items-center"><img
                src="../../../assets/images/Icon_Logout.svg" /><span *ngIf="!IsCollapseSideMenu"
                class="Left_Menu LogoutClr">Logout</span></a></li>
        </ul>
      </div>
      </div>

      <div *ngIf="!IsMobile && UserType == '3'">
        <ul [class.collapsed]="IsCollapseSideMenu" class="Sidemenu">
          <li class="UserDetails">
            <a class="Custom_a" [ngClass]="IsCollapseSideMenu ? 'Li_a_Padding' : ''" (click)="GoToProfile()">
              <div *ngIf="IsCollapseSideMenu" class="CollapseUser"><img src="../../../assets/images/JM/Profile_Icon.png"
                  class="UserImgDefault" /></div>
              <div *ngIf="!IsCollapseSideMenu" class="d-flex justify-content-start align-items-center BorderBott">
                <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
                <span class="UserName">{{UserName}}</span> <!--{{UserName}} Shanti Subramaniam-->
              </div>
            </a>
          </li>


          <li class="" (click)="GoToHome()">
            <a class="MenuGrid">
              <img class="svg" src="../../../assets/images/JM/Home_new.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Home</span>
            </a>
          </li>
          <!-- <li class="" routerLink="/dashboard-ifd" routerLinkActive='active' *ngIf="UserType == '1'">
          <a class="MenuGrid">
            <img src="../../../assets/images/JM/Home_new.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Dashboard IFD</span>
          </a>
        </li> -->
          <!-- <li class="" routerLink="/client-dashboard" routerLinkActive='active' *ngIf="UserType != '3'">
          <a class="MenuGrid">
            <img src="../../../assets/images/JM/Home_new.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Dashboard Client</span>
          </a>
        </li> -->
          <li class="" routerLink="/transaction-new/lumpsum" routerLinkActive='active'>
            <a class="MenuGrid">
              <img src="../../../assets/images/JM/Transact_new.svg" class="svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Online Transaction</span>
            </a>
          </li>
          <li class="" routerLink="/goal-based-investing/dashboard" routerLinkActive='active'>
            <a class="MenuGrid">
              <img src="../../../assets/images/JM/Goal Based Investing_new.svg" class="svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Goal Based Investing</span>
            </a>
          </li>
          <!--Added by Hemengini-->
          <!-- <div [ngClass]="IsShow">
            <li *ngFor="let menu of MenuList" class="nav-item" >
              <a *ngIf="isOnlySingleMenu" class="" routerLink="{{menu.MainMenuURL}}" routerLinkActive="active">
                <img src="../../../assets/images/Box.svg" />
                <span *ngIf="!IsCollapseSideMenu"  class="d-flex align-items-center">{{menu.MainMenuName}}
                </span>
              </a>
              <div *ngIf="!isOnlySingleMenu">
                <a  class="nav-link nav-link_Arrow collapsed text-truncate second" href="#{{menu.SubMenuName}}" data-toggle="collapse" data-target="#{{menu.SubMenuName}}">
                  <img src="../assets/images/Box.svg" />
                    <span *ngIf="!IsCollapseSideMenu">{{menu.MainMenuName}}</span>
                  </a>
                  <div  class="collapse Trans" id="{{menu.SubMenuName}}" aria-expanded="false" *ngIf="!IsCollapseSideMenu">
                    <ul class="flex-column pl-5 nav" >
                      <li class="nav-item" *ngFor="let m of menu.data">
                        <a class="nav-link" routerLink="{{m.SubMenuURL}}" routerLinkActive="active">
                          <span>{{m.SubMenuName}}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
              </div>
            </li>
          </div> -->

          <li class="nav-item">
            <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#fd" data-toggle="collapse"
              data-target="#fd">
              <img src="../../../assets/images/Sidebar/FD.svg" class="svg" />
              <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">FD</span>
            </a>
            <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="fd" aria-expanded="false">
              <ul class="flex-column pl-5 nav">
                <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/fd/fd-landing"
                    routerLinkActive="active"><span>Online FD</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/fd-orderbook"
                    routerLinkActive="active"><span>Order Book</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/bajajfd-orderbook"
                    routerLinkActive="active"><span>Bajaj FD Order Book</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/fd/shriram-fd-orderbook"
                    routerLinkActive="active"><span>Shriram FD Order Book</span></a></li>
              </ul>
            </div>
          </li>
          <!-- <li class="nav-item" *ngIf="UserType == '1'"> *ngIf="UserType == '1'"
          <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#subbroker" data-toggle="collapse"
            data-target="#subbroker">
            <img src="../../../assets/images/Sidebar/Subbroker.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">Subbroker
            </span>
          </a>
          <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="subbroker" aria-expanded="false">
            <ul class="flex-column pl-5 nav">
              <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/referral-report"
                  routerLinkActive="active"><span>Approved</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/referral-pending"
                  routerLinkActive="active"><span>Pending</span></a></li>
              <li class="nav-item"><a class="nav-link DropMenu" routerLink="/subbroker/rm-map"
                  routerLinkActive="active"><span>Map RM</span></a></li>
                  <li class="nav-item"><a class="nav-link DropMenu"  (click)="ShowQRCode();"
                    routerLinkActive="active"><span>Personalized QR Code</span></a></li>
               <li class="nav-item"><a class="nav-link DropMenu" routerLink="/referral-onboarding" routerLinkActive="active"><span>Add New</span></a></li> 
            </ul>
          </div>
        </li> -->

          <!-- <li class="" routerLink="/campaign-management" routerLinkActive='active' *ngIf="UserType == '1'">
          <a class="MenuGrid align-items-center">
            <img src="../../../assets/images/Sidebar/Campaign_Management.svg" class="svg" />
            <span *ngIf="!IsCollapseSideMenu" class="">Campaign Management</span>
          </a>
        </li> -->

          <!-- <li class="nav-item">
            <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#sgb" data-toggle="collapse" data-target="#sgb">
            <img src="../../../assets/images/Sidebar/SGB.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">SGB</span>
            </a>
            <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="sgb" aria-expanded="false">
              <ul class="flex-column pl-5 nav">
                <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/SGB/SGBInvest" routerLinkActive="active"><span>Apply Now</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/SGB/SGBOrderBook" routerLinkActive="active"><span>Order Book</span></a></li>
              </ul>
            </div>
          </li> -->

          <li class="nav-item">
            <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#ipo" data-toggle="collapse"
              data-target="#ipo">
              <img src="../../../assets/images/Sidebar/NCD.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">IPO/NCD</span>
            </a>
            <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="ipo" aria-expanded="false">
              <ul class="flex-column pl-5 nav">
                <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/ipo/dashboardmain"
                    routerLinkActive="active"><span>Dashboard</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/ipo/AddClient"
                    routerLinkActive="active"><span>Add Client</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/ipo/client-bulk-upload"
                    routerLinkActive="active"><span>Client Bulk Upload </span></a></li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link_Arrow collapsed text-truncate" href="#sgb" data-toggle="collapse"
              data-target="#sgb">
              <img src="../../../assets/images/Sidebar/NCD.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="d-none d-sm-inline Left_Menu">SGB</span>
            </a>
            <div *ngIf="!IsCollapseSideMenu" class="collapse Trans" id="sgb" aria-expanded="false">
              <ul class="flex-column pl-5 nav">
                <li class="nav-item"><a class="nav-link DropMenu_1" routerLink="/sgb/SGBInvest"
                    routerLinkActive="active"><span>Apply for SGB</span></a></li>
                <li class="nav-item"><a class="nav-link DropMenu" routerLink="/sgb/SGBOrderBook"
                    routerLinkActive="active"><span>Order Book</span></a></li>
              </ul>
            </div>
          </li>
          <li class="" routerLink="/clients" routerLinkActive='active' *ngIf="UserType != '3'">
            <a class="MenuGrid">
              <img src="../../../assets/images/Sidebar/Clients.svg" class="svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Clients</span>
            </a>
          </li>
          <li class="" routerLink="/explore-funds-new" routerLinkActive='active'>
            <a class="MenuGrid">
              <img src="../../../assets/images/Sidebar/Explore_Funds.svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Explore Funds</span>
            </a>
          </li>

          <li class="" routerLink="/reports/dashboard" routerLinkActive='active' *ngIf="UserType != '3'">
            <a class="MenuGrid">
              <img src="../../../assets/images/JM/Reports.svg" class="svg" />
              <span *ngIf="!IsCollapseSideMenu" class="">Reports</span>
            </a>
          </li>
          <li class="padBtm" (click)="LogOut()"><a class="MenuGrid"><img
                src="../../../assets/images/Icon_Logout.svg" /><span *ngIf="!IsCollapseSideMenu"
                class="Left_Menu ClrRed_n">Logout</span></a></li> <!--PlacedBtm-->


        </ul>
      </div>


      <div *ngIf="IsMobile" class="UserDetails_Mob">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <img src="../../../assets/images/UserProfile_Placeholder.svg" class="UserImg">
            <div style="line-height: 18px;">
              <div class="Hello">Hello</div>
              <div class="UserName_Mob">{{UserName}}</div>
            </div>
          </div>
          <div>
            <img src="../../../assets/images/JM/IFD_Logo.png" alt="Logo" height="38px;" class="logo">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="QRCode" appDisabledRightClick>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content AlertModalContentCls">
        <div class="modal-header AlertModalHeader">QR Code</div>
        <div class="modal-body AlertModalBody">

          <div><img class="SuccessPage" class="SuccessPage" [src]="QRCodeImage"></div>
          <div class="">{{QRCodeURL}} </div>

        </div>
        <div class="modal-footer AlertModalFooter">
          <button type="button" class="CursorPointer" (click)="CopyURL()">
            <img src="../../../assets/images/copy.svg" style="width: 25px;height: 25px;" />
            <span>{{CopyTest}}</span>

          </button>
          <button type="button" (click)="DownloadQRCode()" class="CursorPointer">Download QR Code</button>
          <button type="button" class="CursorPointer" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>