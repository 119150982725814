
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EncrdecrService } from './../Auth/encrdecr.service';
import { MainHeaderMenuService } from './main-header-menu.service';
@Injectable({
  providedIn: 'root'
})
export class TokenExpireService {
  constructor(private EncrdecrService: EncrdecrService,
    private router: Router, public ShowHideHeaderMenu: MainHeaderMenuService) { }

  LogoutUser() {
    this.ShowHideHeaderMenu.hide();
    const CompanyName: string = environment.CompayName;
    var G_UserType = parseInt(this.EncrdecrService.getDecr(sessionStorage.getItem('L2D3506kIHSk3E0'))); //Session_UserType
    const LoginSource = sessionStorage.getItem('6+J3RebINEPBJVbAJw');
    sessionStorage.clear();
    localStorage.clear();
    this.SetCompanyLogo();

    if (G_UserType == 1) {
      this.router.navigateByUrl("/employee-login")
    }
    else if (G_UserType == 2) {
      // this.router.navigateByUrl("/cp-login")
      window.location.href = environment.HDFCFranchiseURL + "login-rp";
    }
    else {
      this.router.navigateByUrl("/login-corp")
    }
  }

  SetCompanyLogo() {
    const CompanyName: string = environment.CompayName;
    switch (CompanyName) {
      case 'HDFC':
        sessionStorage.setItem('logo', '../../assets/images/HDFC_Logo.png');
        sessionStorage.setItem('Abodhj#@jdkd/%^82ncdbcnggshd!@#$', this.EncrdecrService.setEncr("18"));
        sessionStorage.setItem('Abodhj#@jdkd/%^ysudsh&#&shdj@#$', "18");
        sessionStorage.setItem('Mnuoisns6wqgswj2$#', "0");
        break;
    }
  }
}
